import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import Compressor from "@uppy/compressor";
import ImageEditor from "@uppy/image-editor";
import Webcam from "@uppy/webcam";

import { uppyFr } from "./utils";

const documentUpload = document.getElementById("documentUploadDemo");
if (documentUpload) {
    const uppy = new Uppy({
        locale: uppyFr,
        restrictions: {
            maxFileSize: 3000000,
        },
    });
    uppy.use(Dashboard, {
        inline: true,
        target: documentUpload,
        hideUploadButton: true,
        singleFileFullScreen: false,
        proudlyDisplayPoweredByUppy: false,
    });

    uppy.use(Compressor);
    uppy.use(ImageEditor, {
        target: Dashboard,
    });

    uppy.use(Webcam, {
        target: Dashboard,
        showVideoSourceDropdown: true,
        showRecordingLength: true,
    });

    const allUppyEvent = [
        "file-added",
        "file-removed",
        "files-added",
        "file-editor:complete",
    ];

    const inputFile = documentUpload.nextElementSibling;
    inputFile.__uppy = uppy;

    allUppyEvent.forEach((eventName) => {
        uppy.on(eventName, (result) => {
            const inputFile = documentUpload.nextElementSibling;

            const dataTransfer = new DataTransfer();
            uppy.getFiles().forEach((file) => {
                dataTransfer.items.add(file.data);
            });

            inputFile.files = dataTransfer.files;
        });
    });

}
