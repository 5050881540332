import { Carousel } from "@fancyapps/ui/dist/carousel/carousel.esm.js";
import { Thumbs } from "@fancyapps/ui/dist/carousel/carousel.thumbs.esm.js";
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";

if (document.querySelectorAll(`.slider__caroussel`)) {
    const carousels = document.querySelectorAll(`.slider__caroussel`)
    carousels.forEach(carousel => {
        new Carousel(
            carousel,
            {
                transition: "slide",
                preload: 3,
                Navigation: {
                    nextTpl: `<i class="icons-arrow-right"></i>`,
                    prevTpl: `<i class="icons-arrow-left"></i>`
                },
                Dots: false,
                Thumbs: {
                    type: "classic"
                }
            },
            { Thumbs }
        );

        Fancybox.bind('[data-fancybox="gallery"]', {
            compact: false,
            idle: false,
            dragToClose: false,
            contentClick: () =>
                window.matchMedia("(max-width: 578px), (max-height: 578px)").matches
                    ? "toggleMax"
                    : "toggleCover",

            animated: false,
            showClass: false,
            hideClass: false,

            Hash: false,
            Thumbs: false,

            Toolbar: {

                display: {
                    left: [],
                    middle: [],
                    right: ["close"]
                }
            },

            Carousel: {
                transition: "fadeFast",
                preload: 3
            },

            Images: {
                zoom: false,
                Panzoom: {
                    panMode: "mousemove",
                    mouseMoveFactor: 2
                }
            }
        });

    });
}
