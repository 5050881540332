export default function Menu(selector) {
    const btnActions = document.querySelectorAll('.btn--action')
    const openMenu = document.querySelector('.open-btn')
    const menu = document.querySelector(`.${selector}`)
    const links = document.querySelectorAll(`.${selector} .list__link`)
    const menusActive = (document.getElementById('menuActive') ? document.getElementById('menuActive').value : '').split('.');

    btnActions.forEach(ele => {
        const button = ele.querySelector('button')
        button.addEventListener('click', function () {
            button.classList.toggle("actif");
        });
    });

    if (openMenu) {
        openMenu.addEventListener('click', function () {
            const icon = document.querySelector('.open-btn .icons-angle-left')

            if (menu.className === `${selector}`) {
                menu.classList.add('close')
                icon.style.transform = 'rotate(180deg)'

                const slumpBtns = document.querySelectorAll('.menu.close .slump')
                slumpBtns.forEach(slumpBtn => {

                    slumpBtn.addEventListener('click', function () {
                        restoreMenuOpen(menu, icon)
                    })
                });

            } else {
                restoreMenuOpen(menu, icon)
            }
        })
    }

    links.forEach((link) => {
        menusActive.forEach((menuActive) => {
            if (menuActive === link.dataset.type) {

                link.classList.add('active');

                if (link.closest('.slump__content')) {
                    HSCollapse.show(link.closest('.slump__content'));
                }

            }
        });
    });
}

function restoreMenuOpen(menu, icon) {
    menu.classList.remove('close')
    icon.style.transform = 'rotate(0deg)'
}

function isSubmit(e) {
    console.log(e.preventDefault());
}
