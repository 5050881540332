export function FiltersTable() {
    const showFilterBtn = document.querySelector('.show-filter')
    const filtersHidden = document.querySelectorAll('.filter-hide')
    if (showFilterBtn) {
        showFilterBtn.addEventListener('click', () => {
            filtersHidden.forEach(element => {
                element.classList.toggle('filter-show')
                showFilterBtn.querySelector('.btn__content').innerHTML = !element.classList.contains('filter-show') ? 'Plus de filtres' : 'Moins de filtres';
            })
        })
    }
}
